<template>
  <UAlert 
    v-if="!hideNotice"
    :title="title"
    :description="description"
    icon="i-heroicons-information-circle"
    :color="color"
    variant="soft"
    :ui="{
      actions: 'mt-5',
      variant: {
        soft: `ring-1 text-gray-700 dark:text-gray-300 ${notice.color[color].alert}`
      }
    }"
    :close-button="!hideClose ? { icon: 'i-heroicons-x-mark-20-solid', color, variant: 'soft', padded: true } : undefined"
    :actions="btns"
    @close="close"
  >
    <template #icon="{ icon }">
      <div v-if="icon" class="w-7 h-7 sm:h-8 sm:w-8 rounded-md  grid place-items-center shrink-0" :class="notice.color[color].iconBackground">
          <UIcon :name="icon" class="w-4 h-4" :class="notice.color[color].iconText" />
      </div>
    </template>
  </UAlert>
</template>

<script lang="ts" setup>
import type { AlertAction } from '#ui/types'


const props = withDefaults(defineProps<{
  title: string
  description?: string
  to?: string
  label?: string
  color?: BaseColor
  actions?: {
    to?: string
    click?: ((...args: any[]) => void)
    label: string
  }[]
  hideClose?: boolean
}>(), { color: 'secondary' })


const { notice } = useAppConfig().components

const hideNotice = ref(false)

const defaultProps = {
  color: 'secondary',
  variant: 'solid',
  size: '2xs',
} as const

const btns = computed(() => {
  let list: AlertAction[] = []

  if (props.to && props.label) {
    list = [{
      label: props.label,
      to: props.to,
      ...defaultProps
    }]
  }

  if (props.actions) {
    list = [
      ...list,
      ...props.actions.map(a => {
        return {
          ...a,
          ...defaultProps
        }
      })
    ]
  }

  return list
})


const emit = defineEmits<{
  (e: 'close'): void
}>()

const close = () => {
  emit('close')
  hideNotice.value = true
}
</script>

<style>

</style>